
























































/* eslint-disable max-len */
import VueComponent from 'vue'
import { gsap } from 'gsap'

import { HomeOrganizingOrganizer } from '@/inc/types'
import { defineComponent, PropType, ref, onMounted } from '@vue/composition-api'

import ScrollTrigger from 'gsap/dist/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

import UiPicture from '@/components/ui/Picture.vue'

export default defineComponent({
  name: 'HomeOrganizingOrganizer',
  components: { UiPicture },
  props: {
    content: {
      type: Object as PropType<HomeOrganizingOrganizer>,
      required: true,
    },
  },

  setup(props) {
    const root = ref<HTMLElement | null>(null)
    const name = ref<HTMLElement | null>(null)
    const picture = ref<HTMLElement | null>(null)
    const episodePicture = ref<HTMLElement | null>(null)
    const episodeLink = ref<VueComponent | null>(null)
    const isMobile = ref(true)
    let tl: ReturnType<typeof gsap.timeline> | null = null

    const setTimeline = () => {
      const factor = window.innerWidth < 960 ? 1 : 2

      tl = gsap.timeline({
        scrollTrigger: {
          id: `organizer-${props.content.subtitle}`,
          trigger: root.value!,
          start: '-10% 50%',
          end: '110%',
          scrub: 0,
        },
      })

      tl.fromTo(
        episodePicture.value,
        { y: 40 * factor },
        { y: -40 * factor, duration: 2, ease: 'slow(0.3, 0.7, false)' },
        0
      )
        .fromTo(
          episodeLink.value!.$el,
          { y: 50 * factor },
          { y: -40 * factor, duration: 2, ease: 'slow(0.3, 0.7, false)' },
          0
        )
        .fromTo(
          picture.value,
          { y: 50 * factor },
          { y: 0, duration: 2, ease: 'slow(0.3, 0.7, false)' },
          0
        )
    }

    const onResize = (w: number) => {
      const newIsMobile = w < 1024

      if (newIsMobile !== isMobile.value) {
        isMobile.value = newIsMobile

        if (tl) {
          tl.kill()

          if (ScrollTrigger.getById(`organizer-${props.content.subtitle}`)) {
            ScrollTrigger.getById(`organizer-${props.content.subtitle}`).kill()
          }
        }
        gsap.set(
          [episodePicture.value, episodeLink.value!.$el, picture.value],
          { clearProps: 'all' }
        )

        setTimeline()
      }
    }

    onMounted(() => {
      setTimeline()
    })

    return {
      root,
      name,
      picture,
      episodePicture,
      episodeLink,
      onResize,
    }
  },
})
